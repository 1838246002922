// Generated by Framer (1119174)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/zFV_Olyc4";

const enabledGestures = {IR6bImwJR: {hover: true}};

const cycleOrder = ["IR6bImwJR", "XFoBO5mhp"];

const serializationHash = "framer-ILexN"

const variantClassNames = {IR6bImwJR: "framer-v-ctz5ja", XFoBO5mhp: "framer-v-uq4rmo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Default: "IR6bImwJR", Simple: "XFoBO5mhp"}

const getProps = ({height, id, image, link, title, width, ...props}) => { return {...props, gNmNSj0Ps: link ?? props.gNmNSj0Ps, rostjXV0A: image ?? props.rostjXV0A ?? {src: "https://framerusercontent.com/images/ewlZcbfQt8kjTGg81CMiflQRU.webp?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/ewlZcbfQt8kjTGg81CMiflQRU.webp?scale-down-to=512 395w, https://framerusercontent.com/images/ewlZcbfQt8kjTGg81CMiflQRU.webp?scale-down-to=1024 791w, https://framerusercontent.com/images/ewlZcbfQt8kjTGg81CMiflQRU.webp?scale-down-to=2048 1582w, https://framerusercontent.com/images/ewlZcbfQt8kjTGg81CMiflQRU.webp 1938w"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "IR6bImwJR", WJOSViUHL: title ?? props.WJOSViUHL ?? "Landing Page 1"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WJOSViUHL, gNmNSj0Ps, rostjXV0A, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "IR6bImwJR", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "XFoBO5mhp") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-ILexN", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={gNmNSj0Ps} openInNewTab={false}><motion.a {...restProps} className={`${cx("framer-ctz5ja", className)} framer-1o6zhmt`} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"IR6bImwJR"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"IR6bImwJR-hover": {"data-framer-name": undefined}, XFoBO5mhp: {"data-framer-name": "Simple"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", sizes: "min(380px, 100vw)", ...toResponsiveImage(rostjXV0A)}} className={"framer-chxhzi"} data-border layoutDependency={layoutDependency} layoutId={"ml14FfxyQ"} style={{"--border-bottom-width": "1px", "--border-color": "rgba(0, 0, 0, 0.05)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, boxShadow: "0px 10px 20px -20px rgba(0, 0, 0, 0.25)"}}/>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.h3 className={"framer-styles-preset-13xnbb0"} data-styles-preset={"zFV_Olyc4"}>Landing Page 1</motion.h3></React.Fragment>} className={"framer-1av0s94"} layoutDependency={layoutDependency} layoutId={"EiqRrBEQQ"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={WJOSViUHL} verticalAlignment={"top"} withExternalLayout/>)}</motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-ILexN [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ILexN .framer-1o6zhmt { display: block; }", ".framer-ILexN .framer-ctz5ja { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 20px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 380px; }", ".framer-ILexN .framer-chxhzi { aspect-ratio: 0.7833333333333333 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 255px); overflow: hidden; position: relative; width: 100%; will-change: var(--framer-will-change-override, transform); }", ".framer-ILexN .framer-1av0s94 { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ILexN .framer-ctz5ja { gap: 0px; } .framer-ILexN .framer-ctz5ja > * { margin: 0px; margin-bottom: calc(20px / 2); margin-top: calc(20px / 2); } .framer-ILexN .framer-ctz5ja > :first-child { margin-top: 0px; } .framer-ILexN .framer-ctz5ja > :last-child { margin-bottom: 0px; } }", ".framer-ILexN.framer-v-uq4rmo .framer-ctz5ja { cursor: unset; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 526
 * @framerIntrinsicWidth 380
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"XFoBO5mhp":{"layout":["fixed","auto"]},"pmYzF8KIA":{"layout":["fixed","auto"]}}}
 * @framerVariables {"WJOSViUHL":"title","gNmNSj0Ps":"link","rostjXV0A":"image"}
 * @framerImmutableVariables true
 */
const FramerTt0fWvV6T: React.ComponentType<Props> = withCSS(Component, css, "framer-ILexN") as typeof Component;
export default FramerTt0fWvV6T;

FramerTt0fWvV6T.displayName = "Page";

FramerTt0fWvV6T.defaultProps = {height: 526, width: 380};

addPropertyControls(FramerTt0fWvV6T, {variant: {options: ["IR6bImwJR", "XFoBO5mhp"], optionTitles: ["Default", "Simple"], title: "Variant", type: ControlType.Enum}, WJOSViUHL: {defaultValue: "Landing Page 1", displayTextArea: false, title: "Title", type: ControlType.String}, gNmNSj0Ps: {title: "Link", type: ControlType.Link}, rostjXV0A: {__defaultAssetReference: "data:framer/asset-reference,ewlZcbfQt8kjTGg81CMiflQRU.webp?originalFilename=Screenshot+2022-09-12+at+15.25.54.webp&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerTt0fWvV6T, [...sharedStyle.fonts])